define('wearestack-d3/app', ['exports', 'ember', 'wearestack-d3/resolver', 'ember-load-initializers', 'wearestack-d3/config/environment'], function (exports, _ember, _wearestackD3Resolver, _emberLoadInitializers, _wearestackD3ConfigEnvironment) {

  var App = undefined;

  _ember['default'].MODEL_FACTORY_INJECTIONS = true;

  App = _ember['default'].Application.extend({
    modulePrefix: _wearestackD3ConfigEnvironment['default'].modulePrefix,
    podModulePrefix: _wearestackD3ConfigEnvironment['default'].podModulePrefix,
    Resolver: _wearestackD3Resolver['default']
  });

  (0, _emberLoadInitializers['default'])(App, _wearestackD3ConfigEnvironment['default'].modulePrefix);

  exports['default'] = App;
});