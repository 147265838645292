define('wearestack-d3/components/scrolling-box', ['exports', 'ember', 'd3'], function (exports, _ember, _d3) {
  exports['default'] = _ember['default'].Component.extend({

    classNames: 'scrolling-box'

  });
});
// didInsertElement() {
//
//   graphScroll()
//       .sections(d3.selectAll('.scrolling-box > div'))
//       .on('active', function(i){
//         console.log(i + 'th section active', pageYOffset);
//         var mutateGraph = d3.selectAll(".mutanto")
//           .transition().duration(1000)
//           .attr('transform', 'scale(' + 0.08*i + ')');
//         var mutateMiddle = d3.selectAll("#card-middle")
//           .transition().duration(1000)
//           .style('fill', 'rgb(255,' + (255 - (i*20)) + ',255)');
//        });
//
// }