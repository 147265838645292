define("wearestack-d3/templates/components/tumblr-post", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["wrong-type"]
        },
        "revision": "Ember@2.4.4",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 6,
            "column": 27
          }
        },
        "moduleName": "wearestack-d3/templates/components/tumblr-post.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["inline", "tumblr-post-text", [], ["post", ["subexpr", "@mut", [["get", "post", ["loc", [null, [2, 7], [2, 11]]]]], [], []], "postsRoute", ["subexpr", "@mut", [["get", "postsRoute", ["loc", [null, [3, 13], [3, 23]]]]], [], []], "collapsible", ["subexpr", "@mut", [["get", "collapsible", ["loc", [null, [4, 14], [4, 25]]]]], [], []], "collapsed", ["subexpr", "@mut", [["get", "collapsed", ["loc", [null, [5, 12], [5, 21]]]]], [], []], "formatDates", ["subexpr", "@mut", [["get", "formatDates", ["loc", [null, [6, 14], [6, 25]]]]], [], []]], ["loc", [null, [1, 0], [6, 27]]]]],
      locals: [],
      templates: []
    };
  })());
});