define('wearestack-d3/components/contact-form-svg', ['exports', 'ember', 'd3'], function (exports, _ember, _d3) {
    exports['default'] = _ember['default'].Component.extend({

        tagName: 'svg',
        classNames: 'contact-form',

        didInsertElement: function didInsertElement() {
            this.draw();
        },

        draw: function draw() {
            var contactForm = _d3['default'].select(".contact-form");

            contactForm.attr("height", 440).attr("width", 800);

            this.boundingBox = contactForm.append("g").attr("class", "contact-box");

            this.contactBack = this.boundingBox.append("rect").attr("class", "contact-card").attr("id", "contact-card-back").attr("x", 5).attr("y", 5).attr("width", 770).attr("height", 400).style("stroke", "#000").style("fill", "#fff");

            this.contactMiddle = this.boundingBox.append("rect").attr("class", "contact-card").attr("id", "contact-card-back").attr("x", 15).attr("y", 15).attr("width", 770).attr("height", 400).style("stroke", "#000").style("fill", "#fff");

            this.contactFront = this.boundingBox.append("rect").attr("class", "contact-card").attr("id", "contact-card-front").attr("x", 25).attr("y", 25).attr("width", 770).attr("height", 400).style("stroke", "#000").style("fill", "#fff");

            this.contactDiv = this.boundingBox.append("div").attr("id", "contact-form-target");
        }
    });
});