define('wearestack-d3/router', ['exports', 'ember', 'wearestack-d3/config/environment'], function (exports, _ember, _wearestackD3ConfigEnvironment) {

  var Router = _ember['default'].Router.extend({
    location: _wearestackD3ConfigEnvironment['default'].locationType
  });

  Router.map(function () {
    this.route('blog');
  });

  Router.reopen({
    location: 'auto'
  });

  exports['default'] = Router;
});