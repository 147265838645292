define('wearestack-d3/components/d3-box', ['exports', 'ember', 'd3'], function (exports, _ember, _d3) {
    exports['default'] = _ember['default'].Component.extend({

        tagName: 'svg',
        classNames: 'd3-box',

        didInsertElement: function didInsertElement() {
            this.draw();
        },

        draw: function draw() {
            var d3Box = _d3['default'].select(".d3-box");

            d3Box.attr("height", 400).attr("width", 400);

            this.boundingBox = d3Box.append("g").attr("class", "bounding-box");

            this.cardBack = this.boundingBox.append("rect").attr("class", "card-stack").attr("id", "card-back").attr("x", 5).attr("y", 5).attr("width", 300).attr("height", 300).style("stroke", "#000").style("fill", "#fff");

            this.cardMiddle = this.boundingBox.append("rect").attr("class", "card-stack").attr("id", "card-middle").attr("x", 15).attr("y", 15).attr("width", 300).attr("height", 300).style("stroke", "#000").style("fill", "#fff");

            this.cardFront = this.boundingBox.append("rect").attr("class", "card-stack").attr("id", "card-front").attr("x", 25).attr("y", 25).attr("width", 300).attr("height", 300).style("stroke", "#000").style("fill", "#fff");

            this.stageFront = d3Box.append("g").attr("width", 300).attr("height", 300).attr("transform", "translate(30,30)");

            this.random = this.stageFront.append("g").attr("class", "mutanto").append("circle").attr("id", "circle-diffuser").attr("r", 100).attr("cx", 140).attr("cy", 140).style("fill", "#f0f").style("opacity", 0.9);

            // console.log('afterDraw!');
            // graphScroll()
            //     .container(d3.select('#container'))
            //     .graph(d3.selectAll('.d3-box'));
        }
    });
});